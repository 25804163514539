.App {
  color: #838383;
  background-color: #fcf7f0;
}

ken-burns-carousel {
  --img-filter: brightness(80%);
  background-image: url("https://images.unsplash.com/photo-1498496294664-d9372eb521f3");
  background-color: transparent;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
  height: 100%;
  color: white;
}

.place {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
  z-index: 1000;
}

.place a {
  text-decoration: none;
  color: #fff;
  font-size: 50px;
  font-weight: lighter;
}

.place a:hover {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-underline-offset: 12px;
  text-decoration-thickness: 1px;
}

.widget {
  position: absolute;
  right: 0;
  height: 100%;
  max-width: 30vw !important;
  padding: 20px !important;
}

.chat {
  height: 100%;
  overflow: hidden;
}

.messages {
  padding-left: 20px;
}

.chat .messages {
  color: #757575;
  height: 100%;
  overflow: scroll;
}

.chat .user {
  width: max-content;
  max-width: 60% !important;
  font-size: 18px;
}

.chat .spinner-grow {
  color: #c2c2c2 !important;
  --animate-delay: 500ms;
}

.chat .assistant {
  font-size: 20px;
}

.chat .assistant a {
  color: #494949;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.widget input {
  color: #606060 !important;
  position: relative;
  bottom: 50px;
}

.widget input::placeholder {
  color: #c2c2c2;
}

.widget input:focus {
  border: solid 1px #b7b7b7;
}

.burns-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1000;
}

.hotels-list {
  margin-left: 20px;
  margin-right: 20px;
}

.hotels-list .carousel-control-next, .hotels-list .carousel-control-prev {
  width: 50px;
}

.hotels-list .carousel-control-next-icon, .hotels-list .carousel-control-prev-icon {
  filter: brightness(0.8);
  margin-top: 40px;
}

.hotel-card {
  height: 600px !important;
}

.hotel-toggler {
  position: absolute;
  left: 0;
  top: 0;
}

.card-img.hotel-photo {
  width: 600px;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

.animate__animated.animate__delay {
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}